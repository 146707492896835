import './App.css';
import React, { useState, useEffect } from 'react'
import * as rdd from 'react-device-detect';
import Browserinfo from './BrowserInfo_logo.svg';


function App() {
  const [extraInfo, setExtraInfo] = useState()
  const screenSize = window.screen.width + ' x ' + window.screen.height


  // http://ipwhois.app/json/
  // http://ip-api.com/json/
  function getIP() {
    fetch('https://ipapi.co/json/ ', {
      // mode: 'cors',
      //credentials: 'omit', // same-origin, omit, include
    })
      .then(results => results.json())
      .then(results => setExtraInfo(results))
      .catch(err => console.error(err))
  }

  useEffect(() => {
    getIP()
    // console.log(extraInfo)
    // console.clear()
  }, [])

  return (
    <>
      <header>
        <img src={Browserinfo} alt="Browser Info"></img>
      </header>
      <main>
        <div className="infoBox">
          <span>{extraInfo && extraInfo.ip}</span>
          <span>IP address</span>
        </div>
        <div className="infoBox">
          <span>{extraInfo && extraInfo.country}</span>
          <span>Country</span>
        </div>
        <div className="infoBox">
          <span>{extraInfo && extraInfo.org}</span>
          <span>Org</span>
        </div>
        <div className="infoBox">
          <span>{rdd && rdd.osName + ' ' + rdd.osVersion}</span>
          <span>Operating system</span>
        </div>
        <div className="infoBox">
          <span>{rdd && rdd.browserName + ' ' + rdd.browserVersion}</span>
          <span>Browser</span>
        </div>
        <div className="infoBox">
          <span>{screenSize && screenSize}</span>
          <span>Screen size</span>
        </div>
        {/* <div className="infoBox">
          <span>Color depth</span>
          <span>{window.screen.colorDepth} bit</span>
        </div> */}
      </main>
      <footer>
        <p>by <a href="https://www.xcode.no" rel="noreferrer noopener" target="_blank">xCode</a></p>
      </footer>
    </>
  );
}

export default App;
